button.startFromLayout {
    width: 100%;
    @apply tw-inline-flex tw-flex-row tw-font-bold;
    padding: 8px 30px 7px;
    border: solid 1px theme('colors.gray.400');
    &:hover {
        @apply tw-text-white;
        outline: 1px dotted theme('colors.gray.300');
        outline-offset: 1px;
        box-shadow: 0 0 4px theme('colors.gray.400');
        background-color: theme('colors.gray.100');
    }
}


.scrollContainer {
    @screen sm {
        @apply tw-overflow-x-scroll;
        margin: 0 -15px;
        padding: 0 5px;
    }
}

.layoutContainer {
    @apply tw-grid tw-grid-flow-col tw-mb-11;
    grid-template-columns: repeat(auto-fill);
    label {
        width: 138px;
    }
    @screen md {
        @apply tw-overflow-scroll tw-grid-flow-row tw-p-1 tw-gap-4 tw-grid-cols-2;
        justify-content: space-between;
        max-height: 425px;
        label {
            width: unset;
        }
    }
}

.productGroup {
    @apply tw-p-2 tw-flex tw-grid-flow-row tw-content-between;

    &:focus-within {
        box-shadow: none !important;
        color: theme('colors.gray.DEFAULT');
        outline: 1px dotted theme('colors.gray.300');
    }

    &:hover {
        color: theme('colors.gray.DEFAULT');
        outline: 1px dotted theme('colors.gray.300') !important;
    }
}

.productGroupTitle {
    @apply tw-flex tw-pt-4 tw-grow;
    span {
        @apply tw-self-end tw-font-normal;
    }
}

.productGroupSelected {
    border: 1px solid theme('colors.gray.DEFAULT') !important;
    border-bottom: 2px solid theme('colors.gray.DEFAULT') !important; 
    &, &:focus-within, &:hover {
        @apply tw-pb-1;
    }

    .productGroupTitle span {
        @apply tw-font-bold;
    }
}