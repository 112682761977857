

@media screen {
	a.SelectorPromo {
		display: inline-flex;
		flex-direction: row;
		padding: 8px 30px 7px;
		border: solid 1px theme('colors.gray.400');
		text-decoration: none;;
		img {
			margin-right: 15px;
		}
		&:hover {
			outline: 1px dotted theme('colors.gray.300');
			outline-offset: 1px;
			box-shadow: 0 0 4px theme('colors.gray.400');
		}
		p {
			margin-bottom: 0;
		}
	}
}