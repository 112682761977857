

@media screen {
	.DimensionsPromo {
		@apply tw-mb-5;
	}

	.DimensionsPromo {
		@apply tw-mb-5;
		&.DimensionsPromo--withImage {
			a, > div {
				display: flex;
				flex-direction: row;
				align-items: flex-start;
				p {
					margin-bottom: 5px;
				}
				p + p {
					margin-bottom: 0;
				}
				img {
					margin-right: 15px;
				}
			}
		}
	}
	
	a.DimensionsPromo {
		text-decoration: none;
		.DimensionsPromo-link {
			text-decoration: underline;
		}
	}

	li.DimensionsPromo {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		.DimensionsPromo-headline {
			margin-bottom: 5px;
		}

		.DimensionsPromo-button {
			position: relative;
			margin-bottom: 5px;
			&.DimensionsPromo-button--video:after {
				display: block;
				content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNSIgaGVpZ2h0PSIxNyIgdmlld0JveD0iMCAwIDI1IDE3Ij4KICA8ZyBpZD0iVmlkZW8iIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAuNSAwLjUpIj4KICAgIDxyZWN0IGlkPSJSZWN0YW5nbGVfMTcwIiBkYXRhLW5hbWU9IlJlY3RhbmdsZSAxNzAiIHdpZHRoPSIyNCIgaGVpZ2h0PSIxNiIgcng9IjMiIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzM2OSIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBzdHJva2Utd2lkdGg9IjEiLz4KICAgIDxwYXRoIGlkPSJQYXRoXzEzMjciIGRhdGEtbmFtZT0iUGF0aCAxMzI3IiBkPSJNMTg1LjA1LDc1LjM2OGwtNy40LTQuMjcxdjguNTQzWiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTE2OS4xNTMgLTY3LjU5NikiIGZpbGw9IiMzNjkiLz4KICA8L2c+Cjwvc3ZnPgo=");
				width: 24px;
				height: 16px;
				padding: 0 10px;
			}

			@screen md {
				font-size: 16px;
			}
		}
		a {
			margin-bottom: 5px;
		}
	}

	li.SummaryPromo {
		border-bottom: 1px solid theme('colors.gray.300');
		a, button {
			display: flex;
			flex-direction: row;
			align-items: center;
			text-decoration: none;
			justify-content: space-between;
			width: 100%;
			padding-left: 3px;
		}
		button {
			text-align: left;
			cursor: pointer;
		}
		p {
			margin-bottom: 5px;
			line-height: 1.2;
		}
		p+p {
			margin-bottom: 0;
		}
		img {
			margin-left: 15px;
		}
		&:first-child {
			border-top: 1px solid theme('colors.gray.300');
		}
		&:first-child {
			border-top: 0;
		}
		
		&.promoConfig {
			.promoConfigImage {
				margin-left: 14px;
				img {
					margin-left:0px;
					max-width: 100%;
				}
			}
		}
	}

	// Library Promo preview page
	ul.promo-preview {
		list-style: none;
		margin: 0;
		> li {
			margin-bottom: 50px;
		}
		
		li li.SummaryPromo {
			margin-bottom: 35px;
			border-top: 1px solid theme('colors.gray.300');
			border-bottom: 1px solid theme('colors.gray.300');
			display: inline-flex;
		}
		li.DimensionsPromo {
			max-width: 440px;
		}
	}
}